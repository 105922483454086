/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyC0gPqkxRFyJyCGbePkX6U1zT3FL81im8o",
  "appId": "1:402454431448:web:e18e07706f4f583f9eb9ad",
  "authDomain": "schooldog-i-dekalb-ga.firebaseapp.com",
  "measurementId": "G-6H4L08V3N5",
  "messagingSenderId": "402454431448",
  "project": "schooldog-i-dekalb-ga",
  "projectId": "schooldog-i-dekalb-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-dekalb-ga.appspot.com"
}
